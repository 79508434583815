.page{
  padding:85px 0;
  @media (max-width:1024px){
    padding:40px 0;
  }

  @media (max-width:$screen-sm){
    padding-top:25px;
    padding-bottom:20px;
  }

  .main-container{
    @media (max-width:$screen-sm){
      margin-top:0;
    }
  }

  &__header{
    text-align: center;
    font-weight:300;
    color:#333;
    text-align:center;
    position:relative;
    margin-bottom:50px;
    font-size: 5em;
    font-family: 'Arbutus Slab', serif;


    &::before{
      content:'';
      display:block;
      @extend .sprite;
      @extend .sprite.header-logo;
      position:absolute;
      top:-91px;
      right:0;
      left:0;
      margin:auto;
    }

    @media (max-width:$screen-sm){
      font-size:2.5em;
      margin-bottom:25px;
      &::before{
        display:none;
      }
    }
  }

  &__title{
    text-align: center;
  }

  &__text{
    line-height:160%;
    font-size:1.6em;
    color:#333;

    &--error{
    text-align:center;
    font-size:2em;
    }

    &--left{
      text-align: left;
    }

  }

  &__center{
    text-align:center;
    margin-top:30px;
  }

  &__button{
    background:$color1;
    color:$white;
    font-size:1.5em;
    min-width:200px;
    display:inline-block;
    padding:14px 10px;
    text-transform: uppercase;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;


    &:hover,&:focus{
      text-decoration:none;
      color:$white;
    }

    &:hover{
      opacity:0.8;
    }
  }
}