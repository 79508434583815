.menu{
  background:$color3;
  padding: 5px 0;
  position:sticky;
  top:0;
  z-index:100;
  margin-top:-50px;
  box-shadow: 0px 7px 5px 0px rgba(50, 50, 50, 0.2);


  @media (max-width:$screen-md){
    position: fixed;
    width:100%;
    height: 100%;
    z-index: 999;
    background:rgba(0,0,0,0.95);
    display:none;
    overflow-y: auto;
    margin-top:0;
  }

  &__ul{
    list-style-type:none;
    padding:0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top:10px;
    flex-wrap: wrap;

    @media (max-width:$screen-md){
     display:block;
      margin-top:20%;
    }

    @media (max-width:$screen-md) and (orientation: landscape){
      margin-top:5%;
    }

  }

  &__li{
    margin: 0 21px;
    position:relative;
    &::after{
      content:'';
      display:block;
      position:absolute;
      top:0;
      bottom:0;
      right:-25px;
      margin:auto;
      width:6px;
      height:6px;
      background:$color1;
     transform:rotate(45deg);
    }

    &:first-child{
      margin-left:0;
    }

    &:last-child{
      margin-right:0;

      &::after{
        display:none;
      }
    }


    &.active{
      .menu__link{
        font-weight:bold;
        color:$color2;

      }
    }

    @media (max-width:$screen-xlg){
      margin:0 13px;

      &::after{
        right:-16px;
      }
    }


    @media (max-width:$screen-md){

      margin:30px 0;

      &::after{
        display:none;
      }
    }
  }

  &__link{
    text-transform: uppercase;
    color:$color1;
    font-size:1.5em;

    @media (max-width:$screen-lg){
      font-size:1.4em;
    }

    @media (max-width:$screen-md){
      font-size:2.3em;
      color:$white;
    }

    @media (max-width:$screen-sm){
      font-size:2em;
    }

    &:hover,&:focus{
      color:$color2;


      @media (max-width:$screen-md){
        color:$white;
      }
    }

    &:hover,&:focus{
      text-decoration: none;
    }

    &.highlight{
      font-weight:900;
    }


  }

  &__home{
    display:none;
    @media (max-width:$screen-md){
      display:block;
    }
  }

}



.menuButton{
  position:absolute;
  z-index:9999;
  bottom:20px;
  right:10px;
  width:50px;
  height:50px;
  border-radius:100%;
  background:$white;
  display:none;
  padding-top: 15px;
  -webkit-transition: box-shadow 0.3s, opacity 0.3s;
  -moz-transition:box-shadow 0.3s, opacity 0.3s;
  -ms-transition:box-shadow 0.3s, opacity 0.3s;
  -o-transition:box-shadow 0.3s, opacity 0.3s;
  transition:box-shadow 0.3s, opacity 0.3s;

  &.shadow{
    box-shadow:0 0 15px 0 rgba(0,0,0,0.5);
    position:fixed;
    bottom:auto;
    top:20px;
  }

  @media(max-width:$screen-md){
    display:block;

  }

  @media (max-width:$screen-sm){
    right:5px;
    top:100px;
  }



  &__span{
    display:block;
    width:35px;
    margin:0 auto;
    height:4px;
    margin-bottom:4px;
    background:$color1;
  }

  &.active{
   opacity:0;

  }
}



.closeMenuButton{
  position:fixed;
  z-index:9999;
  top:20px;
  right:10px;
  width:50px;
  height:50px;
  border-radius:100%;
  background:$white;
  display:none;

  @media (max-width:$screen-sm){
    right:5px;
  }

  
  span{
    display:block;
    width:35px;
    margin:0 auto;
    height:4px;
    margin-bottom:4px;
    background:$color1;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
  }
  

    span{
      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }



}
