.galleryWidget{
  padding: 139px 0;
  padding-bottom: 50px;
  background:url('/media/default/gallery-bg.jpg')no-repeat;
  background-size:cover;

  @media (max-width: $screen-sm) {
   padding:25px 0;
  }

  &__header {
    font-size: 5em;
    font-weight: 400;
    color: $color1;
    text-align: center;
    position: relative;
    font-family: 'Arbutus Slab', serif;
    padding-top:33px;

    &::before {
      content: '';
      display: block;
      @extend .sprite;
      @extend .sprite.header-logo;
      position: absolute;
      top: -91px;
      right: 0;
      left: 0;
      margin: auto;
    }


    @media (max-width: $screen-sm) {
      font-size: 2.5em;
      &::before {
        display: none;
      }
    }
  }


  &__row{
    display: flex;
    display: -o-flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    margin: 0 -1%;
    margin-top:45px;

    @media (max-width:1599px){
      max-width: 1090px;
      margin-left:auto;
      margin-right:auto;
    }
  }

  &__link{
    margin:0 1%;
    display:block;
    background:$white;
    padding:7px;
    width: 34.3em;
    box-shadow:2px 2px 2px rgba(0,0,0,0.2);
    margin-bottom:30px;

    &:hover,&:focus{
      text-decoration:none;
    }
  }

  &__img{
    max-width: 100%;
    height:auto;
    display:block;
    margin:0 auto;
  }

  &__title{
    color:$color6;
    font-size:1.8em;
    text-align:center;
    margin:10px auto;
    margin-top:20px;
    font-weight:600;
    max-width:24.8em;
    line-height:140%;
  }



  &__button {
    background: $color1;
    text-transform: uppercase;
    color: $white;
    text-align: center;
    width:100%;

    max-width: 350px;
    padding: 20px 10px;
    margin: 0 auto;
    display: block;
    padding: 15px 10px;
    font-size: 1.5em;
    font-weight: bold;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;

    &:hover, &:focus {
      color: $white;
      text-decoration: none;
    }

    &:hover {
      opacity: 0.8;
    }

  }

}