.news {
  background: #fff;
  padding: 85px 0;
  padding-bottom: 50px;

  @media (max-width: $screen-sm) {
    padding-top: 25px;

  }

  .main-container--xsmall {
    @media (max-width: $screen-sm) {
      margin-top: 30px;

      &.no-margin{
        margin-top:0;
      }
    }
  }

  &__header {
    font-size: 5em;
    font-weight: 400;
    color: #333;
    text-align: center;
    position: relative;
    font-family: 'Arbutus Slab', serif;


    @media (max-width: $screen-sm) {
      font-size: 2.5em;

    }
  }

  &__desc {
    line-height: 160%;
    font-size: 2em;
    max-width: 580px;
    margin: 0 auto;
    color: $color3;
    text-align: center;
    font-weight: bold;
    margin-top: 15px;
    letter-spacing: 1px;

    @media (max-width: $screen-md) {
      font-size: 1.8em;
    }
  }

  &__row {
    margin-top: 33px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 1599px) {
      max-width: 1025px;
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: $screen-md) {
      max-width: 768px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: $screen-sm) {
      max-width: 295px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

  }

  &__col {

    width: 28.8em;
    margin: 0 3%;
    margin-bottom: 80px;

    @media (max-width: $screen-sm) {
      width: 100%;
      margin: 0;
      margin-bottom: 60px;
    }

  }

  &__date {
    text-transform: uppercase;
    font-size: 1.4em;
    color: #333;
    margin-bottom: 8px;
    font-weight: 500;

    &--noMargin {
      margin: 0;
    }
  }

  &__content {


    @media (max-width: $screen-sm) {
      min-height: 0;
      padding-bottom: 15px;
    }
  }

  &__title {
    color: #333;
    font-size: 2.2em;
    line-height: 120%;
    margin-bottom:20px;

    font-weight: bold;

    @media (max-width: $screen-md) {
      font-size: 1.5em;
    }

    @media (max-width:767px){
      margin-bottom:0;
    }

    &:hover {
      text-decoration: underline;
    }

    &--large {
      font-size: 4.5em;
      line-height: 140%;
      text-transform: uppercase;
      margin-top: 2px;

      @media (max-width: $screen-md) {
        font-size: 2em;
      }

      &:hover {
        text-decoration: none;
      }
    }

  }

  &__text {
    font-size: 1.6em;
    line-height: 200%;
    color: #333;
    margin: 20px 0;
    &--bold {
      font-weight: bold;
    }
    &--large {
      font-size: 2em;
      line-height: 160%;
      color: $color2;

      @media (max-width: $screen-md) {
        font-size: 1.8em;
      }
    }
  }

  &__description {
    color: #333;
    line-height: 160%;
    font-size: 1.5em;
    margin-top: 7px;
  }



  &__button {
    background: #333;
    text-transform: uppercase;
    color: $white;
    text-align: center;
    width:100%;

    max-width: 350px;
    padding: 20px 10px;
    margin: 0 auto;
    display: block;
    padding: 15px 10px;
    margin-top: 59px;
    font-size: 1.5em;
    font-weight: bold;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;

    &:hover, &:focus {
      color: $white;
      text-decoration: none;
    }

    &:hover {
      opacity: 0.8;
    }

  }

  &__link {
    &:hover, &:focus {
      text-decoration: underline;
      color: $color2;
    }
  }

  &__return {
    color: $color2;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: bold;
    padding-left: 23px;
    position: relative;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;

    &:hover, &:focus {
      color: $color2;
      text-decoration: none;
      opacity: 0.8;
    }

    &::before {
      content: '';
      display: block;
      width: 11px;
      height: 11px;
      border-top: 3px solid $color2;
      border-left: 3px solid $color2;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  &__block {
    margin-top: 20px;

    &--center{
      text-align:center;
    }

    @media (max-width: $screen-sm) {
      margin-top: 25px;
    }
  }

  &__gallery {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -3%;
  }

  &__galleryCol {
    width: 33.33%;
    padding: 3%;

    @media (max-width: $screen-sm) {
      width: 50%;
    }
  }

  &__galleryLink {
    display: block;

  }

  a {
    &:hover {
      img {
        opacity: 0.9;
      }
    }
  }

  &__empty {
    font-size: 2em;
    color: $color8;
    margin: 20px;
    text-align: center;

    @media (max-width: $screen-md) {
      margin:0;
      margin-bottom:30px;
      font-size: 1.5em;
    }
  }

  &__img{
    &--list{
      margin-bottom: 15px;
    }
  }

}

