$default-color: #d23c1c;

$navbar-color: $default-color;
//primary-brand in bootstrap
$panel-color: $default-color;
//success-brand in bootstrap
$button-color: #ff9800;
//body color of primary-panel
$panel-body: #eaeaea;
// light text default color
$light-text: #555;



$screen-xs:                  480px !default;
$screen-sm:                  767px !default;
$screen-md:                  1024px !default;
$screen-lg:                  1199px !default;
$screen-xlg:                  1399px !default;

$black:#000;
$white:#fff;
$color1:#46781F;
$color2:#85B200;
$color3:#EEEEEE;
$color4:#435834;
$color5:#2E3C22;
$color6:#1B2314;
$color7:#ED174C;
$color8:#868686;
$color9:#F4F4F4;
$footer:#002411;
$color10:#666666;
$color11:#DDDDDD;

$dangerBorder:#ebccd1;
$dangerBg:#f2dede;
$dangerColor:#a94442;
$successBorder:#d6e9c6;
$successBg:#dff0d8;
$successColor:#3c763d;



