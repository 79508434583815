.components {
  padding: 50px 0;

  @media (max-width:$screen-lg){
    padding:30px 0;
  }

  @media (max-width:$screen-sm){
    padding:0;
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -30px;

    &--gallery{
      margin:0 -15px;
      @media (max-width:$screen-lg){
        padding: 0 20px;
      }

      @media (max-width:$screen-md){
        padding: 0 10px;
      }

      @media (max-width:$screen-sm){
        padding: 0;
      }
    }

    &--alignItemsCenter {
      align-items: center;
      justify-content: center;

      @media (max-width:$screen-sm){
        margin-bottom:25px;
      }
    }

    @media (max-width:$screen-sm){
      display:block;
      margin-left: 0px;
      margin-right:0px;
    }

  }

  &__col {
    width: 50%;
    padding: 0 30px;

    @media (max-width:$screen-sm){
      width:100%;
      padding:0;
      margin-bottom:25px;
    }

  }

  &__text {
    font-size: 15px;
    color: #333;
    line-height: 200%;
    text-align: left;

    &--singleText {
      max-width: 593px;
      text-align: left;
    }

  }

  &__subHeader {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    line-height: 160%;
    margin-top: 15px;

    @media (max-width:$screen-md){
        font-size:18px;
    }

    &--center {
      text-align: center;
    }
  }

  &__img {
    max-width: 100%;
    height: auto;

    @media (max-width:$screen-sm){
      display:block;
      margin:0 auto;

    }
  }

  &__header {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 45px;
    color: #333;

    @media (max-width:$screen-md){
      font-size:35px;
    }

    &--center {
      text-align: center;
    }
  }

  &__description {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    line-height: 160%;

  }

  &__iframe {
    border: 0;
    width: 100%;
    height: 20vw;

    @media (max-width:$screen-lg){
      height:25vw;
    }

    &--center {
      width: 50%;
    }

    @media (max-width: $screen-md) {
      width: 100%;
      max-width:480px;
      height: 25vw;
      margin: 0 auto;
      display: block;
    }

    @media (max-width: $screen-sm) {
      height: 45vw;
    }

  }

  &__gallery {
    padding: 0 15px;
    margin-bottom: 30px;
    width: 25%;
    text-align:center;


    @media (max-width:$screen-md){
      width: 33.33%;
    }

    @media (max-width:$screen-sm){
      width: 100%;
      padding:0;
      margin-bottom:25px;
    }

  }
}

