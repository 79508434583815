.contact {
  position: relative;
  min-height: 770px;

  @media (max-width: $screen-md) {
    height: auto;
  }

  &__header {

    font-weight: 300;

    color: $white;
    text-align: center;
    position: relative;
    margin-bottom: 86px;
    font-size: 5em;
    font-style: italic;

    &::before {
      content: '';
      display: block;
      @extend .sprite;
      @extend .sprite.header-logo-w;
      position: absolute;
      top: -91px;
      right: 0;
      left: 0;
      margin: auto;
    }

    @media (max-width: $screen-sm) {
      font-size: 2.5em;
      &::before {
        display: none;
      }
    }
  }

  &__container {
    width: 50%;
    text-align: center;
    background: $color5;
    position: absolute;
    padding-left: 15px;
    padding-right: 15px;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    -webkit-transition: left 0.5s;
    -moz-transition: left 0.5s;
    -ms-transition: left 0.5s;
    -o-transition: left 0.5s;
    transition: left 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.active {
      left: -50%;
    }

    @media (max-width: $screen-md) {
      width: 100%;
      position: static;
      padding:50px 15px;
    }

    @media (max-width: $screen-sm) {
      padding-top: 30px;
    }
  }

  &__information {
    max-width: 585px;
    margin: 0 auto;
    margin-top: 30px;
  }

  &__text {
    color: $white;
    font-size: 1.6em;
    font-weight: 400;
    text-align: center;
    margin-bottom: 72px;
    line-height: 160%;
    position: relative;

    span {
      display: block;
      font-weight: bold;
      font-size: 27px;
    }

    a {
      display: block;
      color: $white;

      &:hover, &:focus {
        color: $white;
      }
    }

    &--marker {
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -39px;
        right: 0;
        left: 0;
        margin: auto;
        background: url('/media/default/marker.png');
        width: 23px;
        height: 31px;
      }
    }

    &--phone {
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -39px;
        right: 0;
        left: 0;
        margin: auto;
        background: url('/media/default/phone.png');
        width: 30px;
        height: 28px;
      }
    }

    &--mail {
      margin-bottom:0;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -35px;
        right: 0;
        left: 0;
        margin: auto;
        background: url('/media/default/message.png');
        width: 31px;
        height: 24px;
      }
    }
  }

  &__center {
    text-align: center;
  }

  &__map {
    width: 50%;
    height: 770px;
  float:right;
    transition:width 0.3s;


    &.active{
      width:100%;
    }


    @media (max-width:1024px){
      float:none;
      width: 100%;
    }

  }

  &__mapButton {
    font-size: 0;
    display: block;
    width: 49px;
    height: 49px;
    background: $color5;
    border-radius: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    right: -25px;
    bottom: 0;
    margin: auto;

    @media (max-width: $screen-md) {
      display: none;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }

    &.active {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
      right: -35px;

    }

    &::before {
      opacity: 0.6;
      -webkit-transition: opacity 0.3s;
      -moz-transition: opacity 0.3s;
      -ms-transition: opacity 0.3s;
      -o-transition: opacity 0.3s;
      transition: opacity 0.3s;
      content: '';
      display: block;
      width: 13px;
      height: 13px;
      border: 3px solid rgba(0, 0, 0, 0);
      border-bottom: 3px solid $white;
      border-left: 3px solid $white;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 21px;
      margin: auto;

    }
  }
}