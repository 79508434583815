@font-face {
  font-family: HelveticaF;
  src: url("../../fonts/Helvetica.otf");
}

html, body {
  font-family: 'Open Sans', sans-serif !important;

  @media (max-width: $screen-md) {
    overflow-x: hidden;
  }

  font-size: 0.625em;
}

#pageContent{
  img{
    border:1px solid #ddd;
  }
}

.html--font-small {
  font-size: 1em;
}

.html--font-medium {
  font-size: 1.5em;
}

.html--font-large {
  font-size: 2em;
}

h1, h2, h3, h4, h5, h6 {
  margin: inherit;
}

html {
  overflow-x: hidden;
}

h1, h2 {
  font-size: 5em;
  font-style: italic;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 300 !important;
  text-transform: uppercase;
  margin-bottom: 40px;

  @media (max-width:1024px){
    font-size:30px !important;
  }


}

.overflow {
  overflow: hidden;
}

.clear {
  clear: both;
}

img {
  max-width: 100%;
  height: auto;
}

.main-container {
  max-width: 1630px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;

  &--small {
    max-width: 1270px;
  }

  &--xsmall {
    max-width: 785px;

  }

}

.no-margin {
  margin-top: 0;
}

.pagination-block {
  text-align: center;
  margin-top: 20px;
}

.pagination {
  &__li {

    &.active {
      .pagination__link {
        background: $color6;
        border-color: $color6;
        color: $white !important;

        &:hover {
          background: $color6;
          border-color: $color6;
        }
      }
    }
  }

  &__link {
    color: $color6 !important;

  }

  li {
    &.disabled {
      display: none;
    }
  }

  li > a {
    color: $color6 !important;
    margin: 0 4px;
    font-size: 1.6em !important;
  }

  li > span {
    margin: 0 4px;
    font-size: 1.6em !important;
  }
}

.scrollTop {
  width: 50px;
  height: 50px;
  background: $white;
  border-radius: 100%;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.7);
  position: fixed;
  right: 20px;
  opacity: 0.5;
  font-size: 0;
  -webkit-transition: opacity 0.3s, bottom 07s;
  -moz-transition: opacity 0.3s, bottom 0.7s;
  -ms-transition: opacity 0.3s, bottom 0.7s;
  -o-transition: opacity 0.3s, bottom 0.7s;
  transition: opacity 0.3s, bottom 0.7s;
  bottom: -100px;

  @media (max-width: $screen-md) {
    right: 10px;
  }

  &.show {
    bottom: 30px;
  }

  &:hover {
    opacity: 1;
  }

  &::before {
    content: '';
    display: block;
    border-top: 3px solid $color2;
    width: 11px;
    height: 11px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    margin: auto;
  }
  &::after {
    content: '';
    display: block;
    border-top: 3px solid $color2;
    width: 11px;
    height: 11px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    margin: auto;
  }
}

.preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.85);
  z-index: 10000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: opacity 0.3s;

  &__img {
    display: block;
    margin-bottom: 30px;
  }

  &__content {
    animation: rotate 1s cubic-bezier(0.1, 0.8, 0.8, 0.1) infinite;
  }

  &__dot {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 3px solid $color1;
    display: inline-block;
    margin: 5px;
    background: $color1;
    -webkit-transition: transform 1s;
    -moz-transition: transform 1s;
    -ms-transition: transform 1s;
    -o-transition: transform 1s;
    transition: transform 1s;
    animation: show-hide 1s ease infinite;

    &:nth-child(2) {
      animation-delay: 300ms;
    }

    &:nth-child(3) {
      animation-delay: 600ms;
    }
  }
}

@keyframes show-hide {
  0% {
    transform: scale(0);

  }
  50% {
    transform: scale(1);

  }
  100% {
    transform: scale(0);

  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#preloader.preloader-hiding {
  opacity: 0;
}

#preloader.preloader-hidden {
  display: none;
}

.alert {
  ul {
    padding-left: 15px;
  }
}

.bottom {
  padding: 50px 15px;
  padding-bottom: 70px;
  background: $white;

  &__link {
    max-width: 962px;
    margin: 0 auto;
    background: $color1;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    align-items: center;
    color: $white;
    font-size: 2.6em;
    text-transform: uppercase;
    line-height: 120%;
    font-weight: bold;
    padding: 15px 40px;

    @media (max-width: $screen-md) {
      font-size: 1.6em
    }

    @media (max-width: $screen-sm) {
      display: block;
      padding: 15px;
    }

    &:hover, &:focus {
      color: $white;
    }
  }

  &__col {

    &--left {
      width: 60%;
      padding: 0 15px;

      @media (max-width: $screen-sm) {
        width: 100%;
      }
    }

    &--right {
      width: 40%;
      padding: 0 15px;
      padding-right: 0;

      @media (max-width: $screen-sm) {
        width: 100%;
      }
    }
  }

  &__img {
    @media (max-width: $screen-sm) {
      display: block;
      margin: 0 auto;
      margin-top: 20px;
    }
  }
}

.banner-page {
  width: 100%;
  height: auto;
  display: none;
}

.lang {
  position: absolute;
  top: 10px;
  right: 0;

  &__link {
    font-size: 1.6em;
    text-transform: uppercase;
    color: $color1;
    text-decoration: none;
    font-weight: bold;
    padding: 0 15px;
    border-right: 1px solid $color1;

    &.active {
      color: $color2;
    }

    &:last-child {
      border: 0;
    }

    &:hover,&:focus {
      text-decoration: none;
      color: $color2;
    }

  }
}

.about {
  background: url('/media/default/bg.jpg') no-repeat;
  background-size: cover;

  &__background {
    background: rgba(238, 255, 221, 0.93);
    padding: 85px 0;
    padding-bottom: 150px;

    @media (max-width:1024px){
      padding-bottom:85px;
    }

    @media (max-width:767px){
      padding:40px 0;
    }
  }

  h2 {
    text-align: center;
    color: $color2;
  }

  &__row {
    display: flex;
    flex-direction: row;
    margin: 0 -15px;

    @media (max-width:1024px){
      flex-direction: column;
      align-items: center;
      margin:0 auto;
      max-width:635px;
    }
  }

  &__col {
    padding: 0 15px;
    width: 50%;

    @media (max-width:1024px){
      width: 100%;
      padding:0;
      margin-bottom:30px;
    }
  }

  &__text {
    color: #333333;
    font-size: 17px;
    line-height: 160%;
  }

  &__button {
    display: block;
    max-width: 329px;
    width: 100%;
    background: #85B200;
    border-radius: 10px;
    border: 1px solid #fff;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    margin-top: 0;
    transition: background 0.3s, color 0.3s, border-color 0.3s;
    &:hover, &:focus {
      text-decoration: none;
      color: #85B200;
      background: #fff;
      border-color: #85B200;
    }

    @media (max-width:1024px){
      margin:0 auto;
      margin-top:50px;
    }



  }
}

.section {
  padding: 85px 0;

  @media (max-width:767px){
    padding:40px 0;
  }

  .main-container {
    z-index: 1;
  }

  &--gallery {
    background: #435834;

    h2 {
      color: #fff !important;
    }

  }

  &--dark {
    background: #EEEEEE;
    position: relative;
  }

  &--news {
    min-height: 715px;
  }

  h2 {
    text-align: center;
    color: $color2;
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 -15px;
    position: relative;
    z-index: 2;

    &--wrap{
      flex-wrap: wrap;
    }


    @media (max-width:1024px){
      flex-direction: column;
      margin:0 auto;
      max-width:635px;
    }
  }

  &__offer {
    position: relative;
    margin: 0 15px;
    margin-bottom:30px;


    @media (max-width:1024px){
      margin:0 auto;
      margin-bottom:50px;
      max-width:615px;
    }

    img {
      max-width: 100%;
      height: auto;
      display: block;
    }

    &Content {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.32);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding:10px;

      h3 {
        font-size: 60px;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 120%;
        max-width: 300px;
        margin:0 auto;
        text-align: center;

        @media (max-width:1399px){
          font-size:45px;
        }

        @media (max-width:767px){
          font-size:30px;
        }
      }

      a {
        display: block;
        max-width: 329px;
        width: 100%;
        background: rgba(0, 0, 0, 0);
        border-radius: 10px;
        border: 3px solid #fff;
        padding: 15px;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;
        margin-top: 70px;
        transition: background 0.3s, color 0.3s, border-color 0.3s;
        &:hover, &:focus {
          text-decoration: none;
          color: #85B200;
          background: #fff;

        }

        @media (max-width:767px){
          font-size:16px;
          margin-top:30px;
        }
      }
    }
  }

  &__tree {
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 844px;
    width: 44vw;
    border:0 !important;

    @media (max-width:1024px){
      display: none;
    }
  }

  &__col {
    width: 50%;
    padding: 0 15px;

    @media (max-width:1024px){
      width:100%;
      padding:0;
    }

    h4 {
      color: #333333;
      font-weight: bold;
      font-size: 28px;
      margin-bottom: 10px;
    }

  }

  &__text {
    color: #333333;
    font-size: 17px;
    line-height: 160%;

    &--mt {
      margin-top: 30px;
    }

    &--gallery {
      text-align: center;
      color: #fff;
    }
  }
}

.img-news {
  border: 10px solid #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);

  @media (max-width:1024px){
    margin-bottom:30px;
  }

}

.button {
  display: block;
  width: 100%;
  max-width: 249px;
  background: #85B200;
  border-radius: 10px;
  padding: 15px 0;
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  transition: background 0.3s, color 0.3s;
  margin-top: 30px;
  border: 2px solid #85B200;
  text-decoration: none;
  &:hover, &:focus {
    background: #fff;
    color: #85B200;
    text-decoration: none;
  }


  &--center{
    margin-left:auto;
    margin-right:auto;
  }

  &--gallery {
    margin-left: auto;
    margin-right: auto;
    background: rgba(0, 0, 0, 0);
    text-decoration: none;
    border: 1px solid #fff;

    &:hover {
      background: #fff;
      color: #435834;
      text-decoration: none;
    }
  }

  &--news {
    margin-top: 20px;

    @media (max-width:767px){
      margin-top:0;
    }
  }
}

.gallery-carousel {
  margin-top: 50px;

  @media (max-width: 1399px) {
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 1199px) {
  max-width: 900px;

}

  @media (max-width: 1199px) {
    max-width: 600px;
  }

  @media (max-width:767px) {
    max-width: 240px;
  }

  img {
    display: block;
    border: 7px solid #EEEEEE;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.35);

  }

  .owl-next {
    width: 40px;
    height: 40px;
    border-right: 6px solid #EEEEEE !important;
    border-top: 6px solid #EEEEEE !important;
    transform: rotate(45deg);
    outline: none;
    position: absolute;
    right: -40px;
  }

  .owl-prev {
    width: 40px;
    height: 40px;
    border-left: 6px solid #EEEEEE !important;
    border-bottom: 6px solid #EEEEEE !important;
    transform: rotate(45deg);
    outline: none;
    position: absolute;
    left: -40px;
  }

  .owl-nav {
    position: absolute;
    width: 100%;
    top: 40%;
  }
}