.managements{
  max-width:1255px;
  margin:0 auto;
  &__row{
    display: -o-flex;
    display: -ms-flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    flex-direction: row;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    margin:0 -2%;
    margin-top:70px;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;


    @media (max-width:$screen-md){
      flex-direction: column;
      -ms-flex-direction: column;
      -webkit-flex-direction: column;
    }

    @media (max-width:$screen-sm){
     margin-top:35px;
    }

  }

  &__block{
    background: $white;
    width:45%;
    margin:0 2%;
    margin-bottom:4%;
    padding:25px;
    display: -o-flex;
    display: -ms-flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    flex-direction: row;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;

    @media (max-width:$screen-md){
      width:100%;
      max-width:600px;
      margin-left:auto;
      margin-right:auto;
    }

    @media (max-width:$screen-sm){
     display:block;
      width:100%;
      max-width:320px;
      margin-bottom:20px;
    }

  }

  &__img{
    border:1px solid $color11;
    max-width:100%;
    height:auto;

    @media (max-width:$screen-sm){
      display:block;
      margin:0 auto;
    }
  }

  &__name{
    font-size:2em;
    line-height:120%;
    font-weight:bold;
    padding-bottom:15px;
    border-bottom:1px solid $color10;
    color:$color10;

    @media (max-width:$screen-sm){
      padding-bottom:5px;
    }
  }

  &__place{
    font-size:1.8em;
    line-height:120%;
    margin-top:15px;
    color:$color10;

    @media (max-width:$screen-sm){
     margin-top:5px;
    }

  }

  &__col{
    width:calc(100% - 131px);
    padding-left:30px;

    @media (max-width:$screen-sm){
     width:100%;
      padding:0;
      margin-top:15px;
    }
  }
}