.gallery {
  background: $white;
  padding: 139px 0;
  padding-bottom: 50px;


  @media (max-width: $screen-sm) {
    padding-top: 25px;
    padding-bottom: 0;
  }

  &__header {
    font-size: 5em;
    font-weight: 400;
    color: $color1;
    text-align: center;
    position: relative;
    font-family: 'Arbutus Slab', serif;
    padding-top: 33px;

    &::before {
      content: '';
      display: block;
      @extend .sprite;
      @extend .sprite.header-logo;
      position: absolute;
      top: -91px;
      right: 0;
      left: 0;
      margin: auto;
    }

    @media (max-width: $screen-sm) {
      font-size: 2.5em;
      &::before {
        display: none;
      }
    }
  }

  &__row{
    margin-top:50px;
  }

  &__category{
    width:175px;
    float:left;
    position:sticky;
    position:-webkit-sticky;
    top:70px;
    margin-top: 36px;

    @media (max-width:$screen-md){
      float:none;
      width:100%;

    }
  }

  &__col{
    width:calc(100% - 175px);
    padding-left:80px;
    float:left;

    @media (max-width:$screen-md){
      float:none;
      width:100%;
      padding:0;
      margin-top:30px;
    }

  }

  &__blockHeader{
    font-weight:bold;
    font-size:1.1em;
    color:$color3;
    text-transform: uppercase;
    padding-bottom:7px;
    border-bottom:1px solid $color11;

    @media (max-width:$screen-md){
      text-align:center;
      font-size:1.4em;
    }
  }

  &__arrow{
    display:none;

  }

  &__ul{
    padding:0;
    margin:0;
    padding-top:20px;
    list-style-type: none;

    @media (max-width:$screen-md){
      display: none;
      padding-top:0;
    }
  }

  &__li{
    padding:8px 0;
    @media (max-width:$screen-md){
      text-align: center;
      border-bottom:1px solid $color4;
    }
  }

  &__link{
    color:$black;
    font-size:1.2em;

    @media (max-width:$screen-md){
      font-size:1.4em;

    }

    &.active{
      color:$color1;
      font-weight:bold;
    }

    &:hover,&:focus{
      text-decoration:none;
      color:$black;
    }

    &:hover{
      color:$color1;
    }
  }

  &__year{
    font-size:4.1em;
    color:$color1;
    text-align:left;
    position:relative;
    font-family: 'Arbutus Slab', serif;
    margin-bottom:30px;
    border-bottom:1px solid $color11;

    @media (max-width:$screen-md){
      text-align:center;
    }

    @media (max-width:$screen-sm){
      font-size:3em;

    }


    span{
      background: $white;
      padding: 0 20px;
      padding-left:0;
      display: inline-block;
      z-index: 2;
      position:relative;
    }
  }

  &__list{
    display: flex;
    display: -moz-flex;
    display: -webkit-flex;
    display: -o-flex;
    flex-direction: row;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    margin:0 -2.5%;

    @media (max-width:$screen-sm){
      margin:0;
      display:block;
    }
  }

  &__section{
    margin:0 2.5%;
    margin-bottom:6%;
    max-width:344px;
    width: 28.3%;

    @media (max-width:$screen-sm - 1px){
      display:block;
      max-width:293px;
      margin:0 auto;
      margin-bottom:20px;
      width:100%;
    }

    &:hover,&:focus{
      text-decoration:none;
    }
  }

  &__title{
    font-size:2.2em;
    color:$color3;
    line-height:160%;
    font-weight: bold;
    margin-top:10px;
    word-break: break-all;
  }
  &__return{
    color:$color8;
    text-transform: uppercase;
    font-size:1.5em;
    font-weight:bold;
    padding-left:23px;
    position:relative;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;

    &:hover,&:focus{
      color:$color8;
      text-decoration: none;
      opacity:0.8;
    }

    &::before{
      content:'';
      display:block;
      width:11px;
      height:11px;
      border-top:3px solid $color8;
      border-left:3px solid $color8;
      position:absolute;
      left:0;
      top:0;
      bottom:0;
      margin:auto;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  &__pageTitle{
    color:$color1;
    font-size:3em;
    line-height:120%;
    font-weight:bold;
    text-transform: uppercase;
    margin:0;
    margin-top:40px;

    @media (max-width:$screen-sm){
      font-size:1.8em;
    }

  }

  &__description{
    color:$color3;
    line-height:200%;
    font-size:1.4em;
    margin-top:20px;
  }

  &__listRow{
    display: flex;
    display: -moz-flex;
    display: -webkit-flex;
    display: -o-flex;
    flex-direction: row;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    margin:0 -3%;
    margin-top:30px;
    align-items: center;
    justify-content: center;
  }

  &__item{
    margin:0 3%;
    margin-bottom:3%;
    width: 17%;

    @media (max-width:$screen-md){
      width: 27%;
      margin-bottom:5%;
    }

    @media (max-width:$screen-sm){
      width: 38%;
      margin-bottom:7%;
    }

  }

  &__content{
    font-size:1.6em;
    line-height:160%;
    color:$color3;
    max-width: 785px;

    ul{
      padding-left:20px;
      li{
        margin:20px 0;
      }
    }
  }

  &__img{
    border:1px solid $color5;
  }

}